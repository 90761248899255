<template>
  <!--INSTRUCTION-->
  <div class="instruction">
    <DisplayText :screenProperties="screenProperties"></DisplayText>
  </div>

  <div class="scan-media">
    <img :src="qrReadImage" alt="scan" id="scan-image">
  </div>

  <div class="warning-orange" id="card_read_message">
    <i class="fa-3x fas fa-spinner fa-pulse w3-margin-bottom" id="processing"></i><br>
    <div class="w3-margin-top" v-if="qrCodeError">{{ qrNotFound }}</div>
  </div>

  <div class="w3-hide">
    <QppButton :buttons="buttons" :loaded="qrRead"></QppButton>
  </div>

</template>

<script>


import store from "@/store";
import DisplayText from "@/components/common/DisplayText";
import QppButton from "@/components/common/QppButton";
import screenApiService from "@/services/screenApiService";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";
import helper from "@/services/helper";

const toTranslate = ['qrNotFound'];

export default {
  name: 'QR_READ',
  components: {DisplayText, QppButton},
  data() {
    return {
      screenProperties: {},
      qrNotFound: '',
      qrRead: false,
      buttons: [],
      qrReadImage: null
    }
  },
  mounted() {
    this.buttons = store.state.currentScreenOptions;
    this.screenProperties = store.state.currentScreenProperties;
    this.getTranslation(store.state.languageId, toTranslate);
    this.checkInput();
    screenApiService.showPopup();
    store.state.qrCodeError = false
    let app = this;
    helper.findImage("qrRead.gif").then(function (res) {
      app.qrReadImage = res;
    })

    // for dividing screen in landscape mode
    document.getElementById('inner').classList.add('landscape-inner');
  },
  computed: mapState(['toggleText', 'qrCodeError']),
  watch: {
    qrCodeError(newValue) {
      if (newValue === true) {
        this.getTranslation(store.state.languageId, toTranslate);
        this.qrRead = false;
      }
    },
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id && this.qrNotFound !== '') {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, toTranslate);
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, toTranslate);
        }
      }
    }
  },
  beforeUnmount() {
    screenApiService.resetPopup();
    document.getElementById('inner').classList.remove('landscape-inner');
  },
  methods: {
    checkInput() {
      let typedString = "";
      let app = this;
      document.addEventListener("keypress", function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
          // on ENTER
          e.preventDefault();
          app.scanQR(typedString);
          typedString = "";
        } else typedString += e.key;
      });
    },
    scanQR(qrcode) {
      store.state.qrCodeError = false
      console.log("Scanned code: " + qrcode);

      store.commit('setqrCodeData', qrcode);
      this.qrRead = true;
    },
    getTranslation(lang = store.state.languageId, keys) {
      let app = this;
      if (lang) {
        keys.forEach(function (key) {
          screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
            app[key] = response.data;
          }).catch(function () {
            console.log("No translation found for fe." + key + " in language " + lang);
            app[key] = '-'
          })
        })
      }
    },
  }
}
</script>
